import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/security/Login'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/orders',
    name: 'main',
    children: [
      {
        path: 'cakeshops',
        name: '',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            name: 'cakeshops-list',
            component: () => import('@/views/cakeshop/CakeshopList')
          },
          {
            path: 'new',
            name: 'cakeshops-new',
            component: () => import('@/views/cakeshop/CakeshopNew')
          },
          {
            path: ':uuid/profile',
            name: 'cakeshop-profile',
            component: () => import('@/views/cakeshop/CakeshopProfile')
          },
          {
            path: ':cakeshopId/cakes/:cakeId',
            name: 'cake-profile',
            component: () => import('@/views/cakeshop/CakeProfile')
          },
          {
            path: ':cakeshopId/addresses',
            name: 'cakeshop-address-profile',
            component: () => import('@/views/cakeshop/CakeshopAddressProfile')
          },
          {
            path: 'baker',
            name: 'baker-check',
            component: () => import('@/views/cakeshop/BakerCheck')
          },
        ]
      },
      {
        path: 'orders',
        name: '',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            name: 'orders-list',
            component: () => import('@/views/order/OrderList')
          },
          {
            path: ':uuid/profile',
            name: 'order-profile',
            component: () => import('@/views/order/OrderProfile')
          }
        ]
      },
      {
        path: 'campaigns',
        name: '',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            name: 'campaigns-list',
            component: () => import('@/views/campaign/CampaignList')
          },
          {
            path: 'new',
            name: 'campaigns-new',
            component: () => import('@/views/campaign/CampaignNew')
          },
          {
            path: ':uuid/profile',
            name: 'campaign-profile',
            component: () => import('@/views/campaign/CampaignProfile')
          },
        ]
      },
      {
        path: 'clients',
        name: '',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: '',
            name: 'clients-list',
            component: () => import('@/views/client/ClientList')
          },
        ]
      },
    ]
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  //mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
  router.go()
}

export default router

