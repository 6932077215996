import {getClients} from '../../api/client-api';

const initialState = {
    list: []
};

const mutations = {
    SET_CLIENTS_LIST(state, clients) {
        state.list = clients;
    }
};

const actions = {
    loadClients({commit}) {
        return new Promise((resolve, reject) => {
            getClients()
                .then(clients => {
                    commit('SET_CLIENTS_LIST', clients);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clearClients({commit}) {
        return new Promise((resolve) => {
            commit('SET_CLIENTS_LIST', []);
            resolve()
        })
    }
};

export default {
    state: initialState,
    mutations,
    actions
}
