<template>
    <div id="container" class="d-flex flex-row justify-content-between align-items-center">

        <div class="nav-item">
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelectTab" :router="true">
                <el-menu-item index="orders" :route="{ name: 'orders-list' }">
                    <svg-icon style="width: 1.5em; height: 1.5em;" class="mr-1" icon-class="orders" class-name=""/>Pedidos
                </el-menu-item>
                <el-menu-item v-if="role === 'ADMIN'" index="cakeshops" :route="{ name: 'cakeshops-list' }">
                   <svg-icon style="width: 1.5em; height: 1.5em;" class="mr-1" icon-class="cakeshops" class-name=""/>Pastelerías
                </el-menu-item>
                <el-menu-item v-else-if="role === 'BAKER'" index="cakeshops" :route="{ name: 'baker-check' }">
                    <svg-icon style="width: 1.5em; height: 1.5em;" class="mr-1" icon-class="cakeshops" class-name=""/>Mi Pastelería
                </el-menu-item>
                <el-menu-item v-if="role === 'ADMIN'" index="campaigns" :route="{ name: 'campaigns-list' }">
                    <svg-icon style="width: 1.5em; height: 1.5em;" class="mr-1" icon-class="giftcard" class-name=""/>Campañas
                </el-menu-item>
                <el-menu-item v-if="role === 'ADMIN'" index="clients" :route="{ name: 'clients-list' }">
                  <svg-icon style="width: 1.5em; height: 1.5em;" class="mr-1" icon-class="user" class-name=""/>Clientes
                </el-menu-item>
            </el-menu>
        </div>

<!--        <div class="text-center">-->
<!--            <img class="" height="25" src="@/assets/images/logo.png" alt="Logo de Wecake">-->
<!--        </div>-->

        <div class="nav-item text-right">
            <span>{{name}}</span>
            <img width="24" src="@/assets/images/icon-admin.png" alt="Icono de wecake" class="ml-2">
            <img class="logout ml-3" width="16" src="@/assets/images/icon-exit.png" alt="Icono de logout" @click="logout">
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        computed: {
            ...mapGetters([
                'name',
                'role',
                'orders'
            ])
        },
        data() {
            return {
                activeIndex: null,
            };
        },
        created() {
            this.$store.dispatch('loadOrders').then(() => {
                if (this.orders.length === 0 && this.role === 'BAKER') {
                    this.$router.push({ name: 'baker-check'})
                }
            });
        },
        mounted() {
            this.setActiveIndexByPath(this.$route.path);
        },
        watch: {
            '$route.path': function(path) {
                this.setActiveIndexByPath(path);
            }
        },
        methods: {
            async logout() {
                await this.$store.dispatch('user/logout')
                this.$router.push(`/login`)
            },
            handleSelectTab(link) {
                this.activeIndex = link;
            },
            setActiveIndexByPath(path) {
                if (/^\/orders/.exec(path)) {
                    this.activeIndex = 'orders';
                } else if (/^\/cakeshops/.exec(path)) {
                    this.activeIndex = 'cakeshops';
                } else if (/^\/campaigns/.exec(path)) {
                    this.activeIndex = 'campaigns';
                } else if (/^\/clients/.exec(path)) {
                  this.activeIndex = 'clients';
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    #container {
        margin-left: 10em;
        margin-right: 10em;
    }
    .logout {
        cursor: pointer;
    }
    .el-menu.el-menu--horizontal {
        border-bottom: none !important;
        a {
            text-decoration: none;
        }
    }
    .nav-item {
        flex: 1 1 0;
    }
    .is-active {
        font-weight: bold;
    }
</style>
