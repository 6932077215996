import {getCakeshops, getBakerCakeshops} from '../../api/cakeshop-api';

const initialState = {
    list: []
};

const mutations = {
    SET_CAKESHOP_LIST(state, cakeshops) {
        state.list = cakeshops;
    }
};

const actions = {
    loadCakeshops({commit}) {
        return new Promise((resolve, reject) => {
            getCakeshops()
                .then(cakeshops => {
                    commit('SET_CAKESHOP_LIST', cakeshops);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    loadCakeshopsByBaker({commit}, bakerId) {
        return new Promise((resolve, reject) => {
            getBakerCakeshops(bakerId)
                .then(cakeshops => {
                    commit('SET_CAKESHOP_LIST', cakeshops);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clearCakeshops({commit}) {
        return new Promise((resolve) => {
            commit('SET_CAKESHOP_LIST', []);
            resolve()
        })
    }
};

export default {
    state: initialState,
    mutations,
    actions
}
