import request from '@/utils/request'

export function getOrders() {
    return request({
        url: '/orders',
        method: 'get'
    })
}

export function getOrder(uuid) {
    return request.get('/orders/' + uuid);
}

export function cancel(uuid, commentary, fromCakeshop) {
    return request.post('/orders/' + uuid + '/cancel', {
        commentary,
        fromCakeshop
    });
}

export function setPrices(uuid, cakePrice, shippingPrice) {
    return request.post('/orders/' + uuid + '/prices', {
        cakePrice,
        shippingPrice
    });
}

export function setStatusAsPendingApproval(uuid, commentary) {
    return request.post('/orders/' + uuid + '/pending-approval', {
        commentary
    });
}

export function deliver(uuid) {
    return request.post('/orders/' + uuid + '/deliver');
}

export function saveNote(uuid, note) {
    return request.post('/orders/' + uuid + '/notes', {note});
}