import request from '@/utils/request'

export function getCampaigns() {
    return request.get('/campaigns')
}

export function getCampaign(uuid) {
    return request.get('/campaigns/' + uuid)
}

export function activateCampaign(uuid) {
    return changeCampaignStatus(uuid, 'PUBLISHED')
}

export function disableCampaign(uuid) {
    return changeCampaignStatus(uuid, 'INACTIVE')
}

function changeCampaignStatus(uuid, status) {
    return request.post('/campaigns/' + uuid + '/status', {
        status: status
    });
}

export function newCampaign(campaign) {
    const url = '/campaigns';
    let data = buildCampaignData(campaign);
    return request.post(url, data);
}

export function updateCampaign(uuid, campaign) {
    let data = buildCampaignData(campaign);
    return request.post('/campaigns/' + uuid, data);
}

function buildCampaignData(campaing) {
    return {
        "name": campaing.name,
        "description": campaing.description,
        "startDate": campaing.startDate,
        "endDate": campaing.endDate,
        "useWithFavorite": campaing.useWithFavorite,
        "type": campaing.type,
        "code": campaing.code,
        "discount": campaing.discount,
        "usesLimit": campaing.totalUses != null && campaing.usesPerPerson != null,
        "totalUses": campaing.totalUses,
        "usesPerPerson": campaing.usesPerPerson,
        "scopeLimit": campaing.cities.length > 0 || campaing.tags.length > 0 || campaing.cakeshops.length > 0 || campaing.cakes.length > 0,
        "citiesIds": campaing.cities.map(city => city.uuid),
        "tagsIds": campaing.tags.map(tag => tag.uuid),
        "cakeshopsIds": campaing.cakeshops.map(cakeshop => cakeshop.uuid),
        "cakesIds": campaing.cakes.map(cake => cake.uuid),
    }
}

export function deleteCampaign(uuid) {
    return request.delete('/campaigns/' + uuid);
}
