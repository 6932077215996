import {getTags} from '../../api/tag-api'

const initialState = {
    list: []
}

const mutations = {
    SET_TAG_LIST(state, tags) {
        state.list = tags
    }
}

const actions = {
    loadTags({commit}) {
        return new Promise((resolve, reject) => {
            getTags()
                .then(tags => {
                    commit('SET_TAG_LIST', tags)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                });
        });
    },
    clearTags({commit}) {
        return new Promise((resolve) => {
            commit('SET_TAG_LIST', [])
            resolve()
        })
    }
}

export default {
    state: initialState,
    mutations,
    actions,
}
