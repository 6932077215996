import router from '../../router'
import store from '../../store'
import { getToken } from '../../utils/auth'

router.beforeEach(async (to, from, next) => {
    if (getToken()) {
        if (store.getters.tags.length < 1) {
            store.dispatch('loadTags')
        }
        if (store.getters.allergens.length < 1) {
            store.dispatch('loadAllergens')
        }
    }
    next()
})
