import {getAllergens} from '../../api/allergen-api'

const initialState = {
    list: []
}

const mutations = {
    SET_ALLERGEN_LIST(state, allergens) {
        state.list = allergens
    }
}

const actions = {
    loadAllergens({commit}) {
        return new Promise((resolve, reject) => {
            getAllergens()
                .then(allergens => {
                    commit('SET_ALLERGEN_LIST', allergens)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                });
        });
    },
    clearAllergens({commit}) {
        return new Promise((resolve) => {
            commit('SET_ALLERGEN_LIST', [])
            resolve()
        })
    }
}

export default {
    state: initialState,
    mutations,
    actions,
}
