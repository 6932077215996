import {getCampaigns} from '../../api/campaign-api';

const initialState = {
    list: []
};

const mutations = {
    SET_CAMPAIGN_LIST(state, campaigns) {
        state.list = campaigns;
    }
};

const actions = {
    loadCampaigns({commit}) {
        return new Promise((resolve, reject) => {
            getCampaigns()
                .then(campaigns => {
                    commit('SET_CAMPAIGN_LIST', campaigns);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clearCampaigns({commit}) {
        return new Promise((resolve) => {
            commit('SET_CAMPAIGN_LIST', []);
            resolve()
        })
    }
};

export default {
    state: initialState,
    mutations,
    actions
}
