import {login} from '@/api/user-api'
import {getToken, setToken, removeToken} from '@/utils/auth'
import {resetRouter} from '@/router'

const state = {
    token: getToken(),
    name: '',
    role: '',
    uuid: '',
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_ROLE: (state, role) => {
        state.role = role
    },
    SET_UUID: (state, uuid) => {
        state.uuid = uuid
    },
}

const actions = {
    login({commit}, loginData) {
        const {username, password} = loginData
        return new Promise((resolve, reject) => {
            login({username: username.trim(), password: password}).then(response => {
                if (response.role !== 'ADMIN' && response.role !== 'BAKER') {
                    reject()
                } else {
                    commit('SET_TOKEN', response.token)
                    commit('SET_ROLE', response.role)
                    commit('SET_UUID', response.uuid)
                    commit('SET_NAME', username)
                    setToken(response.token)
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    logout({commit, dispatch}) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            commit('SET_ROLE', '')
            commit('SET_UUID', '')
            commit('SET_NAME', '')
            dispatch('clearOrders', null, {root: true})
            dispatch('clearCities', null, {root: true})
            dispatch('clearCakeshops', null, {root: true})
            dispatch('clearCampaigns', null, {root: true})
            dispatch('clearClients', null, {root: true})
            dispatch('clearTags', null, {root: true})
            dispatch('clearAllergens', null, {root: true})
            removeToken()
            resetRouter()
            resolve()
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

