import {getOrders} from '../../api/order-api';

const initialState = {
    list: []
};

const mutations = {
    SET_ORDER_LIST(state, orders) {
        state.list = orders;
    }
};

const actions = {
    loadOrders({commit}) {
        return new Promise((resolve, reject) => {
            getOrders()
                .then(orders => {
                    commit('SET_ORDER_LIST', orders);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clearOrders({commit}) {
        return new Promise((resolve) => {
            commit('SET_ORDER_LIST', []);
            resolve()
        })
    }
};

export default {
    state: initialState,
    mutations,
    actions
}
