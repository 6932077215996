import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es'

import 'bootstrap/dist/css/bootstrap.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/theme/index.css';
import '@/assets/styles/index.scss';

import '@/permission';
import './plugin/router/master-data-load';
import '@/icons';

import './prototype-extensions';

Vue.config.productionTip = false;
Vue.use(ElementUI, {locale});
Vue.use(require('vue-moment'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
