const getters = {
    token: state => state.user.token,
    name: state => state.user.name,
    role: state => state.user.role,
    uuid: state => state.user.uuid,
    cities: state => state.city.list,
    orders: state => state.order.list,
    orderById: state => (orderId) => {
        return state.order.list.find(order => (order.uuid === orderId));
    },
    cakeshops: state => state.cakeshop.list,
    cakeshopById: state => (cakeshopId) => {
        return state.cakeshop.list.find(cakeshop => (cakeshop.uuid === cakeshopId));
    },
    campaigns: state => state.campaign.list,
    campaignById: state => (campaignId) => {
        return state.campaign.list.find(campaign => campaign.uuid === campaignId);
    },
    tags: state => state.tag.list,
    tagById: state => (tagId) => {
        return state.tag.list.find(tag => (tag.uuid === tagId));
    },
    allergens: state => state.allergen.list,
    allergenById: state => (allergenId) => {
        return state.allergen.list.find(allergen => (allergen.uuid === allergenId));
    },
    clients: state => state.client.list,
}
export default getters
