<template>
  <div class="app-wrapper">
    <div class="main-container">
      <div class="fixed-header">
        <navbar/>
        <el-divider/>
      </div>
      <app-main/>
    </div>
  </div>
</template>

<script>
import { Navbar, AppMain } from './components'

export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain
  },
}
</script>

<style lang="scss" scoped>

  .app-wrapper {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    transition: width 0.28s;
    background-color: white;
  }

  .app-main {
    padding: 70px 10em 0 10em;
  }

  .el-divider {
    margin: 0;
  }
</style>
