import request from '@/utils/request'

export function getCakeshops() {
    return request({
        url: '/cakeshops',
        method: 'get'
    })
}

export function getBakerCakeshops(bakerId) {
    return request({
        url: '/cakeshops?bakerId=' + bakerId,
        method: 'get'
    })
}

export function getCakeshop(uuid) {
    return request.get('/cakeshops/' + uuid)
}

export function newCakeshop(data) {
    return request.post('/cakeshops', data)
}

export function updateCakeshop(uuid, data) {
    return request.post('/cakeshops/' + uuid, data)
}

export function deleteCakeshop(uuid) {
    return request.delete('/cakeshops/' + uuid)
}

export function activateCakeshop(uuid) {
    return changeCakeshopStatus(uuid, 'PUBLISHED')
}

export function disableCakeshop(uuid) {
    return changeCakeshopStatus(uuid, 'INACTIVE')
}

function changeCakeshopStatus(uuid, status) {
    return request.post('/cakeshops/' + uuid + '/status', {
        status: status
    });
}
