import axios from 'axios'
import store from '@/store'
import {getToken} from '@/utils/auth'
import {Message} from "element-ui";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + "/api", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: process.env.REQUESTS_TIMEOUT // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token && config.url !== '/login') {
            config.headers['Authorization'] = "Bearer " + getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const responseData = response.data

        if (response.status !== 200 && response.status !== 201) {
            return Promise.reject(new Error(responseData || 'Error'))
        } else {
            return responseData
        }
    },
    error => {
        if (error.response === undefined) {
            Message({showClose: true, message: "¡Error al conectar con el servidor!", type: 'error'});
        } else if (error.response.status === 401 && error.response.data.message === 'Expired JWT Token') {
            store.dispatch('user/logout')
        } else {
            return Promise.reject(error)
        }
    }
)

export default service
