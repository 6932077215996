import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
import city from './modules/city'
import order from './modules/order'
import cakeshop from './modules/cakeshop'
import campaign from './modules/campaign'
import client from './modules/client'
import tag from './modules/tag'
import allergen from './modules/allergen'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    user,
    city,
    order,
    cakeshop,
    campaign,
    client,
    tag,
    allergen,
  },
  getters,
})

export default store
