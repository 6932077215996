import {getCities} from '../../api/city-api';

const initialState = {
    list: []
};

const mutations = {
    SET_CITY_LIST(state, cities) {
        state.list = cities;
    }
};

const actions = {
    loadCities({commit}) {
        return new Promise((resolve, reject) => {
            getCities()
                .then(cities => {
                    commit('SET_CITY_LIST', cities);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    clearCities({commit}) {
        return new Promise((resolve) => {
            commit('SET_CITY_LIST', []);
            resolve()
        })
    }
};

export default {
    state: initialState,
    mutations,
    actions
}
